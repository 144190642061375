(function ($) {
  $(document).on('product.updateCrossSells.finished', '.js-cross-sell-grid-container', function (e) {
    Drupal.behaviors.productCrossSellGrid.update($(this));
  });

  Drupal.behaviors.productCrossSellGrid = {
    update: function (context) {
      var $grids = $('.product-grid', context);
      var $wrapper = $('.product-grid-wrapper', context);
      var hasQuickshop = $grids.hasClass('product-grid--quickshop');
      var $carouselGrids = $('.js-cross-sell-grid-carousel', context);
      var $carousels = $('.js-product-carousel', $carouselGrids);
      var $carouselsItems = $('.js-grid-item', $carousels);
      var minSlidesToShow = 3;

      if (site.client.isMobile) {
        minSlidesToShow = 1;
      }

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      // @setup - new brand to adjust responsive/dots settings per designs
      $carousels.each(function () {
        if ($carouselsItems.length <= minSlidesToShow) {
          return;
        }
        var arrowsDiv = $(this).parent().find('.js-carousel-controls');
        var dotsDiv = $(this).parent().find('.js-carousel-dots');
        var settings = {
          appendArrows: arrowsDiv,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                appendDots: dotsDiv,
                arrows: true,
                dots: true,
                slidesToShow: minSlidesToShow,
                slidesToScroll: 2
              }
            }
          ]
        };
        // slidesToShow override
        var slidesToShowCount = $(this).data('slides-to-show');

        if (slidesToShowCount) {
          settings.slidesToShow = slidesToShowCount;
        }

        // Init this carousel with our settings
        $(this).slick(settings);

        // On before slide change
        $(this).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          // Remove quickshop:
          if (hasQuickshop && Drupal.behaviors.quickshop) {
            $('.js-quickshop', $wrapper).remove();
          }
        });
      }); // $carousels.each
    } // update
  };
})(jQuery);
